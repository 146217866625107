import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';
import { HighlightedHeadline } from 'components/Shared';
import { Row, Container as Grid, Col, media } from 'styled-bootstrap-grid';

const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.palette.success.light};

  #st-page-wrapper {
    max-height: unset !important;
  }

  // 680 is the mobile breakpoint in the consumer app, so let's make sure that this stays the same
  @media only screen and (min-width: 680px) {
    padding: 40px 2rem 5.5rem;
  }
`;

const ContentGrid = styled(Grid)`
  position: relative;
  z-index: 10;
  padding: 0;

  ${media.xl`
      padding: 0px 88px;
  `}
`;

const ContentColumn = styled(Col)`
  height: 100%;
  justify-content: center;
  align-items: flex-start;
  text-align: start;
`;

const HeadlineContainer = styled.div`
  padding: 2rem 1rem 0;
  margin-bottom: 24px;

  ${media.xl`
    display: flex;
    justify-content: center;
  `}
`;

const TextContainer = styled.div`
  margin-bottom: 40px;
  font-size: 16px;
  font-weight: 300;
  padding: 0 1rem;

  ${media.xl`
    text-align: center;
  `}
`;

const ProductSliderWidget = ({
  data: {
    api_key,
    headline_pre_highlight: preHighlight,
    headline_highlight: highlight,
    headline_post_highlight: postHighlight,
    text,
  },
  language,
}) => {
  const [script, setScript] = useState('');

  // get local param from language string e.g. en-US => en
  const lang = language.slice(0, 2);

  useEffect(() => {
    // Only set script after the component is ready
    // to make sure `st-product-overview` is rendered before loading the script
    setScript(
      `https://products.seedtrace.org/api/slider.js?apiKey=${api_key.text}&locale=${lang}`
    );
  }, []);

  return (
    // Extra wrapper needed for the script to have a stable container
    <div id="react-helmet-product-slider-container">
      <Container>
        <ContentGrid>
          <Row alignItems="stretch" justifyContent="start">
            <ContentColumn col={12} alignItems="start" justifyContent="start">
              <HeadlineContainer>
                <HighlightedHeadline
                  pre={preHighlight}
                  highlight={highlight}
                  post={postHighlight}
                  highlightNewline={false}
                  hasDarkBackground={false}
                />
              </HeadlineContainer>
              {text && <TextContainer>{text.text}</TextContainer>}
              {/* Rendering the widget */}
              <div id="st-product-overview" />
              <Helmet
                // this solves the problem that when the widget is used several times on the gatsby website, the script is not loaded again.
                defer={false}
                script={[
                  {
                    src: script,
                  },
                ]}
              />
            </ContentColumn>
          </Row>
        </ContentGrid>
      </Container>
    </div>
  );
};

export default ProductSliderWidget;
